import useIds from "./idsHook";

const { XMLParser } = require("fast-xml-parser");

export interface ISocSentinelWebApi {
    //getAccessTockenFromCode: (code: string) => Promise<IToken>;
}

export interface IAuthShuttle {
    cryptedIds: string
}

export interface IContactShuttle {
    authShuttle: IAuthShuttle | null,
    vendorId?: number | null,
    customerId: number | null,
    contactId?: string | null,
    emailId?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    locale?: string | null,
    defaultField: boolean | undefined
}

export interface ICustomerSearchShuttle {
    authShuttle: IAuthShuttle,
    customerName?: string | null,
    crmId?: string | null,
    refId?: string | null,
    customerRefId?: string | null,
    pageIndex?: number | null,
    pageSize?: number | null,
    sortCol?: string | null,
    sortOrder?: string | null,
    vendorId?: number | null,
}

export interface ICustomerDetailsShuttle {
    authShuttle: IAuthShuttle,
    customerId?: string,
    crmId?: string
    vendorId?: number,
    name?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    city?: string,
    country?: string,
    state?: string,
    street?: string,
    zip?: string,
    type?: CustomerType
}

export enum CustomerType {
    org = 0,
    ind = 1
}

export interface ICustomerContactsShuttle {
    authShuttle: IAuthShuttle,
    customerId?: string,
    crmId?: string
}

export interface ICustomerEntitlementsShuttle {
    authShuttle: IAuthShuttle,
    customerId?: string,
    crmId?: string,
    pageIndex?: number,
    pageSize?: number,
    keyId?: string
}

export interface IEntitlementsShuttle {
    authShuttle: IAuthShuttle,
    customerId?: string,
    keyID?: string,
    entId?: number,
    vendorId?: number
}

export enum LicenseModelType {
    Perpetual = 1,
    ExpirationDate = 2
}

export enum EntitlementAction {
    NONE = -1,
    SAVE = 0,
    QUEUE = 1,
    PRODUCE = 2,
    ENABLE = 3,
    DISABLE = 4,
    REOPEN = 5,
    REVOKE = 6,
}

export enum EntitlementState {
    Unknown = -1,
    Draft = 0,
    Queued = 1,
    ProductKeysGenerated = 2,
    Produced = 3,
    Completed = 4,
    Acknowledged = 5
}

export interface IEntitlementCreateShuttle {
    entId?: number,
    authShuttle: IAuthShuttle,
    vendorId?: number,
    licenseModelType?: LicenseModelType,
    productIds?: number[],
    customerEmail?: string,
    customerId?: number,
    refID1?: string,
    concurrentInstances?: number,
    countCriteria?: string,
    startDate?: Date,
    endDate?: Date,
    description?: string,
    entitlementAction?: EntitlementAction,
    keyID?: string,
    sendMail?: boolean,
    network?: boolean,
}

export interface IProductSearchShuttle {
    authShuttle: IAuthShuttle,
    vendorId?: number | null,
    productName?: string | null,
    lifeCycleStage?: string | null,
    refId1?: string | null,
    refId2?: string | null,
    pageIndex?: number | null,
    pageSize?: number | null,
    sortCol?: string | null,
    sortOrder?: string | null,
}

export interface ISOC_listResponse {
    instances?: IInstance[],
    totalCount?: number,
    pageIndex?: number,
    pageSize?: number,
    totalPages?: number,
    sortCol?: string,
    sortOrder?: string
}

export interface IInstance {

    id?: number,
    idSpecified?: boolean,
    name?: string,
    refId1?: string,
    refId2?: string,
    fieldId?: string,
    memoryDescription?: string,
    identifier?: string,
    type?: string,
    lifeCycleStage?: string,
    state?: string,
    createDate?: string,
    commitDate?: string,
    contact?: string,
    customer?: string,
    partner?: string,
    batchCode?: string,
    descr?: string,
    lockType?: string,
    enabled?: boolean,
    enabledSpecified?: boolean,
    deployed?: boolean,
    deployedSpecified?: boolean,
    keysNumber?: string,
    partnerName?: string,
    partnerEmail?: string,
    actPerKey?: string,
    activationDate?: string,
    customerId?: string,
    partnerId?: string,
    needConfirm?: boolean,
    needConfirmSpecified?: boolean,
    customerRefId?: string,
    phone?: string,
    fax?: string,
    billingAddress?: string,
    billingZip?: string,
    billingCity?: string,
    billingCountry?: string,
    billingState?: string,
    vendorId?: string,
    defaultEmail?: string,
    defaultContactName?: string,
    contactId?: string,
    emailId?: string,
    cloneProtection?: boolean,
    cloneProtectionSpecified?: boolean,
    partnerRefId?: string,
    default?: boolean,
    firstName?: string,
    middleName?: string,
    lastName?: string,
    locale?: string,
    crmId?: string
}

export class SocSentinelWebApi implements ISocSentinelWebApi {
    protected urlBase = "https://localhost:7095";
    //protected urlBase = "https://9dfc-2a01-e0a-22d-20e0-ec5f-957a-2b45-82bd.ngrok-free.app";
    //protected urlBase = "https://sentinel-api.development.socinformatique.fr";
    //protected urlBase = "https://sentinel-api.socinformatique.fr";

    private Login: string = "";
    private Password: string = "";

    public Auth(ids: IAuthShuttle): Promise<string> {
        return new Promise<string>((resolve: (result: string) => void, reject: (error: any) => void): void => {

            const [getIds, secretKey] = useIds();

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(ids);
            fetch(this.urlBase + '/Sentinel/Auth', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.text().then(u => {
                            const parser = new XMLParser();
                            let jObj = parser.parse(u)
                            if (Object.keys(jObj).length > 0) {
                                let CryptoJS = require("crypto-js");
                                let myIdsStr = CryptoJS.AES.decrypt(ids.cryptedIds, secretKey).toString(CryptoJS.enc.Utf8);
                                let myIdsDeserialized = JSON.parse(myIdsStr);
                                if (jObj.authenticationDetail.userName === myIdsDeserialized.login) resolve(u);
                                else reject(u);
                            }
                            else reject(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerSearch(customerShuttle: ICustomerSearchShuttle): Promise<ISOC_listResponse> {
        return new Promise<ISOC_listResponse>((resolve: (result: ISOC_listResponse) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(customerShuttle);
            fetch(this.urlBase + '/Sentinel/CustomerSearch', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(await response.json());
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerGetID(shuttle: ICustomerDetailsShuttle): Promise<string> {
        return new Promise<string>((resolve: (result: string) => void, reject: (error: any) => void): void => {
            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerGetID', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(await response.json());
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerDetails(shuttle: ICustomerDetailsShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerDetails', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(await response.json());
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerCreate(shuttle: ICustomerDetailsShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerCreate', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(await response.json());
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerDelete(shuttle: ICustomerDetailsShuttle): Promise<boolean> {
        return new Promise<boolean>((resolve: (result: boolean) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerDelete', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(true);
                    } else {
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerUpdate(shuttle: ICustomerDetailsShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerUpdate', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(async err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }


    public CustomerContacts(shuttle: ICustomerContactsShuttle): Promise<IInstance[]> {
        return new Promise<IInstance[]>((resolve: (result: IInstance[]) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerContacts', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public ContactCreate(shuttle: IContactShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/ContactCreate', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public ContactDelete(shuttle: IContactShuttle): Promise<boolean> {
        return new Promise<boolean>((resolve: (result: boolean) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/ContactDelete', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(true);
                    } else {
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public ContactUpdate(shuttle: IContactShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/ContactUpdate', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public CustomerEntitlements(shuttle: ICustomerEntitlementsShuttle): Promise<any> {
        return new Promise<any[]>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/CustomerEntitlements', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public ProductSearch(productrShuttle: IProductSearchShuttle): Promise<ISOC_listResponse> {
        return new Promise<ISOC_listResponse>((resolve: (result: ISOC_listResponse) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(productrShuttle);
            fetch(this.urlBase + '/Sentinel/ProductSearch', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public EntitlementCreateOrUpdate(shuttle: IEntitlementCreateShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/EntitlementCreateOrUpdate', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public EntitlementDelete(shuttle: IEntitlementCreateShuttle): Promise<any> {
        return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/EntitlementDelete', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        resolve(true);
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public EntitlementsByKeyID(shuttle: IEntitlementsShuttle): Promise<any[]> {
        return new Promise<any[]>((resolve: (result: any[]) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/EntitlementsByKeyID', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public GetEntitlementCreateShuttle(shuttle: IEntitlementsShuttle): Promise<any> {
        return new Promise<any[]>((resolve: (result: any[]) => void, reject: (error: any) => void): void => {

            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/GetEntitlementCreateShuttle', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }

    public EntitlementsByID(shuttle: IEntitlementsShuttle): Promise<any[]> {
        return new Promise<any[]>((resolve: (result: any[]) => void, reject: (error: any) => void): void => {
            const headers = {
                "Content-Type": "application/json"
            };

            const body = JSON.stringify(shuttle);
            fetch(this.urlBase + '/Sentinel/EntitlementsByID', {
                method: "POST",
                headers: headers,
                body: body
            })
                .then(async response => {
                    if (response.ok) {
                        response.json().then(u => {
                            resolve(u);
                        });
                    } else {
                        //debugger;
                        reject(await response.text());
                    }
                }).catch(err => {
                    //debugger;
                    reject(err.message);
                });
        });
    }
}